import {
  capitalizeWords,
  propertySubTypeOptions,
  viewOptions as viewOptionsRaw
} from '@/helpers/search';
import { joinAnd, joinOr } from '@/helpers/utils';
import type {
  ProjectDocumentResponse,
  PropertyDocumentResponse
} from '@/types/properties';
import type { SearchParams } from '@/types/search';

interface SearchParamsWithProject extends SearchParams {
  isProject: boolean;
}

export default function () {
  const { t, n } = useNuxtApp().$i18n;

  const { language } = useLanguage();

  // const hopaOptions = hopaOptionsRaw.map(v => ({
  //   ...v,
  //   text: t(v.text)
  // }));

  const viewOptions = viewOptionsRaw.map(v => ({
    ...v,
    value: (
      v[('value-' + language.value) as 'es'] ||
      v.value ||
      v[language.value as 'es'] ||
      v.en ||
      ''
    ).toLowerCase(),
    text: v[language.value as 'es'] || v.en
  }));

  // Text formatting utilities
  function formatFirstLetter(text: string, toUpperCase: boolean) {
    return toUpperCase
      ? text.charAt(0).toUpperCase() + text.slice(1)
      : text.charAt(0).toLowerCase() + text.slice(1);
  }

  function capitalizeFirstLetter(text: string) {
    return formatFirstLetter(text, true);
  }

  function decapitalizeFirstLetter(text: string) {
    return formatFirstLetter(text, false);
  }

  function capitalize(word: string) {
    if (word === 'on' || word === 'at' || word === 'the' || word === 'by') {
      return word;
    }
    if (word === 'sls') {
      return 'SLS';
    }
    return capitalizeFirstLetter(word);
  }

  // Property type mapping utilities
  const subTypeToTypeMap = computed(() => {
    return propertySubTypeOptions.reduce((acc, cur) => {
      acc[cur.value] = t(cur.typeText);
      return acc;
    }, {} as Record<string, string>);
  });

  const subTypeMap = computed(() => {
    return propertySubTypeOptions.reduce((acc, cur) => {
      acc[cur.value] = t(cur.text);
      return acc;
    }, {} as Record<string, string>);
  });

  // Search SEO utilities
  function getSearchSeoTitle(query: SearchParamsWithProject) {
    return getSearchSeo(query, 'title');
  }

  function getSearchSeoDescription(query: SearchParamsWithProject) {
    return getSearchSeo(query, 'description');
  }

  // Property SEO utilities
  function getPropertySeoTitle(property: PropertyDocumentResponse) {
    const { full: address, id: mlsId, subType, type: buyType } = property;

    // Determinar el tipo de propiedad basado en subType
    let str =
      subType === 'townhouse' ||
      subType === 'villa' ||
      subType === 'singleFamilyResidence'
        ? t('search.house').toLowerCase()
        : t('search.apartment').toLowerCase();

    // Determinar si es venta o renta
    str =
      buyType === 'RNT'
        ? t('seo.singleRentAlt', [str]).toLowerCase().trim()
        : t('seo.singleSaleAlt', [str]).toLowerCase().trim();

    let addressStr = address;

    let count = 0;

    while (addressStr.length >= 45 && count <= 2) {
      addressStr = addressStr
        .split(/,\s*|FL\s+/)
        .slice(0, -1)
        .join(', ');
      count++;
    }

    addressStr = addressStr.trim() || address;

    str = addressStr ? t('connectors.in', [str, addressStr]) : str;

    return `${capitalize(str)} | MLS #${mlsId} | PFS Realty`;
  }

  function getPropertySeoDescription(property: PropertyDocumentResponse) {
    return getPropertySeo(property, 'description');
  }

  function getPropertySeoAddress(property: PropertyDocumentResponse) {
    return getPropertySeo(property, 'address');
  }

  function getPropertySchemaName(
    property: PropertyDocumentResponse,
    index?: number
  ) {
    const { full: address, subType } = property;

    // Get the translated subType using the existing subTypeMap
    let str = subTypeToTypeMap.value[subType] || t('search.property');

    if (index) {
      str += ` ${index}`;
    }

    if (address) {
      str = t('connectors.in', [str, address]);
    }

    return str;
  }

  function getPropertySchemaDescription(property: PropertyDocumentResponse) {
    const { full: address, subType, view, bedrooms } = property;

    const finalView = Array.isArray(view) ? view[0] : view;

    // Get the translated property type
    const typeLabel = subTypeToTypeMap.value[subType] || t('search.property');

    const subTypeLabel = subTypeMap.value[subType] || t('search.property');

    let str =
      typeLabel !== subTypeLabel
        ? t('connectors.type', [typeLabel, subTypeLabel])
        : typeLabel;

    if (bedrooms) {
      str = t('connectors.ofProperty', [
        str,
        t('search.nBedrooms', [bedrooms])
      ]);
    }

    // Get the context from the subType using the existing subTypeContextMap
    // const context = subTypeContextMap[subType] || 'female';

    // Start with exclusive property type using the correct gender context
    // str = t(`seo.exclusive${context === 'female' ? '_female' : ''}`, [
    //   str
    // ]);

    // Add view if available
    if (finalView && typeof finalView === 'string') {
      const viewOption = viewOptions.find(
        v => v.value.toLowerCase() === finalView.toLowerCase()
      );

      if (viewOption) {
        let viewText = viewOption.text?.toLowerCase();
        viewText = viewText?.slice(0, 1).toLowerCase() + viewText?.slice(1);
        viewText = viewText?.replace(/\b(wf\/)\b/gi, 'WF/');
        str = t(`connectors.${viewOption.connector}`, [str, viewText]);
      }
    }

    // Add location
    if (address) {
      str = t('connectors.in', [str, address]);
    }

    // Add period at the end
    return `${str}.`;
  }

  // Project SEO utilities
  function getProjectSeoTitle(project: ProjectDocumentResponse) {
    const { address, city, projectName, subType } = project;

    // Seleccionar el tipo correcto basado en subType
    let str =
      subType === 'townhouse' ||
      subType === 'villa' ||
      subType === 'singleFamilyResidence'
        ? t('search.housesCondoType').toLowerCase()
        : t('search.apartmentsCondoType').toLowerCase();

    str = t('search.itemsForSale', {
      items: capitalize(str)
    })
      .toLowerCase()
      .trim();

    str = projectName ? t('connectors.in', [str, projectName]) : str;

    const fullAddress = [address, city].filter(Boolean).join(', ');
    str = fullAddress ? `${str} ${fullAddress}` : str;

    return `${capitalize(str)} | PFS Realty`;
  }

  function getProjectSeoDescription(project: ProjectDocumentResponse) {
    const {
      city,
      projectName,
      subType,
      stories,
      minBeds,
      maxBeds,
      minBaths,
      maxBaths
    } = project;

    // Determine the type of property based on subType
    const isHouseType =
      subType === 'townhouse' ||
      subType === 'villa' ||
      subType === 'singleFamilyResidence';

    const typeStr = isHouseType
      ? t('search.housesCondosType').toLowerCase()
      : t('search.apartmentsCondosType').toLowerCase();

    // Construct base description
    let str = t('seo.discover', [typeStr]);
    str = t('search.itemsForSale', { items: str });

    // Use gender and plural specific connector
    const locatedInKey = isHouseType
      ? 'connectors.locatedIn_female_plural'
      : 'connectors.locatedIn_plural';

    const fullAddress = [projectName, city].filter(Boolean).join(', ');

    str = fullAddress ? t(locatedInKey, [str, fullAddress]) : str;

    // Build features array
    const features = [];

    if (stories) {
      features.push(t('search.nStories', [stories]));
    }

    if (minBeds) {
      if (minBeds === maxBeds) {
        features.push(t('search.nBedrooms', [minBeds]));
      } else {
        features.push(t('search.nBedrooms', [`${minBeds}-${maxBeds}`]));
      }
    }

    if (minBaths) {
      if (minBaths === maxBaths) {
        features.push(t('search.nBathrooms', [minBaths]));
      } else {
        features.push(
          t('connectors.upTo', [t('search.nBathrooms', [maxBaths])])
        );
      }
    }

    // Join features and add to description
    if (features.length > 0) {
      const featuresStr = joinAnd(features, t);
      str = t('connectors.hasFeatures', [str, featuresStr]);
    }

    // Add family complement
    str = t('seo.forFamily', [str]);

    return str;
  }

  // Search SEO
  function getSearchSummary(query: Partial<SearchParams>) {
    const defaultItemLabel = t('search.properties');

    let itemsLabel = defaultItemLabel;

    let context = 'male';

    if (query.propertyType?.length === 1) {
      if (query.propertyType[0] === 'apartment') {
        itemsLabel = t('search.condosType');
      } else if (query.propertyType[0] === 'house') {
        itemsLabel = t('search.housesType');
        context = 'female';
      }
    }

    if (query.view) {
      const viewOption = viewOptions.find(
        v => v.value.toLowerCase() === query.view.toLowerCase()
      );

      if (viewOption) {
        let viewText = viewOption?.text?.toLowerCase();
        viewText = viewText?.slice(0, 1).toLowerCase() + viewText?.slice(1);
        viewText = viewText?.replace(/\b(wf\/)\b/gi, 'WF/');
        const newStr = t(`connectors.${viewOption.connector}`, [
          itemsLabel,
          viewText
        ]);
        if (newStr === `connectors.${viewOption.connector}`) {
          console.warn('[SEARCH] Error printing summary, wrong option', {
            viewOption,
            query
          });
        } else {
          itemsLabel = newStr;
          itemsLabel =
            itemsLabel.slice(0, 1).toUpperCase() + itemsLabel.slice(1);
        }
      }
    }

    let str = '';

    if (query.county === 'constempty') {
      delete query.county;
    }

    if (query.city === 'constempty') {
      delete query.city;
    }

    if (query.zipcode === 'constempty') {
      delete query.zipcode;
    }

    if (query.neighborhood === 'constempty') {
      delete query.neighborhood;
    }

    if (query.location === 'constempty') {
      delete query.location;
    }

    const geolocation = query.location === 'constgeolocation';

    if (geolocation) {
      delete query.location;
    }

    const location = [
      query.county,
      query.city,
      query.zipcode && `FL ${query.zipcode}`,
      capitalizeWords(query.neighborhood),
      capitalizeWords(query.location)
    ]
      .filter(Boolean)
      .join(', ');

    if (geolocation) {
      if (query.buyType === 'RNT') {
        str = t('search.rentSearchResultsNearYou', [itemsLabel]);
      } else if (query.buyType === 'SOL') {
        str = t('search.soldSearchResultsNearYou', [itemsLabel]);
      } else if (query.buyType === 'RTD') {
        str = t('search.rentedSearchResultsNearYou', [itemsLabel]);
      } else if (query.buyType === 'NEW') {
        str = t('search.buySearchResultsNearYou', [itemsLabel]);
      } else {
        str = t('search.buySearchResultsNearYou', [itemsLabel]);
      }
    } else if (location) {
      if (query.buyType === 'RNT') {
        str = t('search.rentSearchResults', {
          search: location,
          items: itemsLabel
        });
      } else if (query.buyType === 'SOL') {
        str = t('search.soldSearchResults', {
          search: location,
          items: itemsLabel
        });
      } else if (query.buyType === 'RTD') {
        str = t('search.rentedSearchResults', {
          search: location,
          items: itemsLabel
        });
      } else if (query.buyType === 'NEW') {
        str = t('search.buySearchResults', {
          search: location,
          items: itemsLabel
        });
      } else {
        str = t('search.buySearchResults', {
          search: location,
          items: itemsLabel
        });
      }
    } else if (query.buyType === 'RNT') {
      str = t('search.itemsForRent', {
        items: itemsLabel
      });
    } else if (query.buyType === 'SOL') {
      str = t(
        context === 'male' ? 'search.soldItems' : 'search.soldItems_female',
        {
          items: itemsLabel
        }
      );
    } else if (query.buyType === 'RTD') {
      str = t(
        context === 'male' ? 'search.rentedItems' : 'search.rentedItems_female',
        {
          items: itemsLabel
        }
      );
    } else if (query.buyType === 'NEW') {
      str = t(
        context === 'male' ? 'search.newItems' : 'search.newItems_female',
        {
          items: itemsLabel
        }
      );
    } else {
      str = t('search.itemsForSale', {
        items: itemsLabel
      });
    }

    let typeLabel = '';

    if (query.propertySubType?.length === 1) {
      typeLabel =
        subTypeMap.value[query.propertySubType[0]] || defaultItemLabel;

      if (['Condominium', 'Townhouse'].includes(query.propertySubType[0])) {
        context = 'male';
      }
    }

    if (typeLabel) {
      str = t('connectors.type', [str, typeLabel]);
    }

    if (query.inListing) {
      str = t('search.inListingItems', [str]);
    }

    if (query.priceFrom && !isNaN(+query.priceFrom)) {
      str = t('connectors.from', [
        str,
        t('seo.numberArticle', [n(+query.priceFrom, 'currency')])
      ]);
    }

    if (query.priceTo && !isNaN(+query.priceTo)) {
      str = t('connectors.to', [
        str,
        t('seo.numberArticle', [n(+query.priceTo, 'currency')])
      ]);
    }

    const features: [string, 'with' | 'from' | 'to' | 'without'][] = [];

    if (
      query.bedroomsFrom &&
      !query.bedroomsTo &&
      !isNaN(+query.bedroomsFrom)
    ) {
      features.push([
        t('search.nOrMoreBedrooms', [query.bedroomsFrom]),
        'with'
      ]);
    }

    if (query.bedroomsTo && !query.bedroomsFrom && !isNaN(+query.bedroomsTo)) {
      features.push([t('search.nOrLessBedrooms', [query.bedroomsTo]), 'with']);
    }

    if (
      query.bedroomsFrom &&
      query.bedroomsTo &&
      !isNaN(+query.bedroomsFrom) &&
      !isNaN(+query.bedroomsTo)
    ) {
      features.push([
        t('search.nBedrooms', [
          [query.bedroomsFrom, query.bedroomsTo].join('-')
        ]),
        'with'
      ]);
    }

    if (
      query.bathroomsFrom &&
      !query.bathroomsTo &&
      !isNaN(+query.bathroomsFrom)
    ) {
      features.push([
        t('search.nOrMoreBathrooms', [query.bathroomsFrom]),
        'with'
      ]);
    }

    if (
      query.bathroomsTo &&
      !query.bathroomsFrom &&
      !isNaN(+query.bathroomsTo)
    ) {
      features.push([
        t('search.nOrLessBathrooms', [query.bathroomsTo]),
        'with'
      ]);
    }

    if (
      query.bathroomsFrom &&
      query.bathroomsTo &&
      !isNaN(+query.bathroomsFrom) &&
      !isNaN(+query.bathroomsTo)
    ) {
      features.push([
        t('search.nBathrooms', [
          [query.bathroomsFrom, query.bathroomsTo].join('-')
        ]),
        'with'
      ]);
    }

    if (query.areaFrom || query.areaTo) {
      let areaStr = '';

      if (query.areaFrom) {
        areaStr = t('connectors.from', [
          areaStr,
          t('seo.numberArticle', [t('search.nArea', [query.areaFrom])])
        ]);
      }

      if (query.areaTo) {
        areaStr = t('connectors.to', [
          areaStr,
          t('seo.numberArticle', [t('search.nArea', [query.areaTo])])
        ]);
      }

      areaStr = t('connectors.ranging', [t('search.areas'), areaStr]);

      features.push([areaStr, 'with']);
    }

    if (query.yearFrom || query.yearTo) {
      const connectorType = !query.yearFrom ? 'to' : 'from';
      let yearStr = '';

      if (query.yearFrom) {
        yearStr = t('seo.numberArticleSingle', [query.yearFrom]);
      }

      if (query.yearTo) {
        yearStr = query.yearFrom
          ? t('connectors.to', [
              yearStr,
              t('seo.numberArticleSingle', [query.yearTo])
            ])
          : t('seo.numberArticleSingle', [query.yearTo]);
      }

      features.push([yearStr, connectorType]);
    }

    if (query.shortTermLease) {
      features.push([t('search.shortTermLease').toLowerCase(), 'with']);
    }

    if (query.excludePendingAndUnderContract) {
      features.push([
        t('search.pendingAndUnderContractProperties').toLowerCase(),
        'without'
      ]);
    }

    const withFeatures = features.filter(f => f[1] === 'with').map(f => f[0]);
    const fromFeatures = features.filter(f => f[1] === 'from').map(f => f[0]);
    const toFeatures = features.filter(f => f[1] === 'to').map(f => f[0]);
    const withoutFeatures = features
      .filter(f => f[1] === 'without')
      .map(f => f[0]);

    if (withFeatures.length > 0) {
      str = t('connectors.with', [str, joinAnd(withFeatures, t)]);
    }

    if (fromFeatures.length > 0) {
      str = t('connectors.from', [str, joinAnd(fromFeatures, t)]);
    }

    if (toFeatures.length > 0) {
      str = t('connectors.to', [str, joinAnd(toFeatures, t)]);
    }

    if (withoutFeatures.length > 0) {
      str = t('connectors.without', [str, joinOr(withoutFeatures, t)]);
    }

    if (['hopa-eligible', 'no-hopa'].includes(query.hopa || '')) {
      str += ` (${t(`search.hopaOptions.${query.hopa}`)})`;
    }

    return str;
  }

  function getSearchSeo(
    queryArg: SearchParamsWithProject,
    type: 'title' | 'description' = 'title'
  ) {
    const query = { ...queryArg };

    let str = '';

    const defaultItemLabel = query.isProject
      ? query.buyType === 'NEW'
        ? t('search.projects')
        : t('search.buildings')
      : t('search.properties');

    let itemsLabel = defaultItemLabel;

    let context = 'male';

    if (query.propertyType?.length === 1) {
      if (query.propertyType[0] === 'apartment') {
        itemsLabel = t('search.condosType');
      } else if (query.propertyType[0] === 'house') {
        itemsLabel = t('search.housesType');
        context = 'female';
      }
    }

    const propertySubType = [
      ...new Set(query.propertySubType?.map(p => subTypeMap.value[p]) || [])
    ];

    let typeStr = '';

    if (itemsLabel && propertySubType?.length === 1) {
      typeStr = t('connectors.type', [itemsLabel, propertySubType[0]]);
    } else if (itemsLabel) {
      typeStr = itemsLabel;
    } else if (propertySubType?.length === 1) {
      typeStr = propertySubType[0];
    }

    if (query.buyType === 'RNT') {
      str = t('search.itemsForRent', {
        items: typeStr
      });
    } else if (query.buyType === 'SOL') {
      str = t(
        context === 'male' ? 'search.soldItems' : 'search.soldItems_female',
        {
          items: typeStr
        }
      );
    } else if (query.buyType === 'RTD') {
      str = t(
        context === 'male' ? 'search.rentedItems' : 'search.rentedItems_female',
        {
          items: typeStr
        }
      );
    } else if (query.buyType === 'NEW') {
      str = t(
        context === 'male' ? 'search.newItems' : 'search.newItems_female',
        {
          items: typeStr
        }
      );
    } else if (query.isProject) {
      str = t(
        context === 'male' ? 'search.usedItems' : 'search.usedItems_female',
        {
          items: typeStr
        }
      );
    } else {
      str = t('search.itemsForSale', {
        items: typeStr
      });
    }

    if (query.inListing) {
      str = t('search.inListingItems', [str]);
    }

    if (type === 'description') {
      const strUsingFind = t('search.findItems', {
        items: decapitalizeFirstLetter(str)
      });

      str = strUsingFind || str;
    }

    if (query.view) {
      const viewOption = viewOptions.find(
        v => v.value.toLowerCase() === query.view.toLowerCase()
      );

      if (viewOption) {
        let viewText = viewOption?.text?.toLowerCase();
        viewText = viewText?.slice(0, 1).toLowerCase() + viewText?.slice(1);
        viewText = viewText?.replace(/\b(wf\/)\b/gi, 'WF/');
        const newStr = t(`connectors.${viewOption.connector}`, [str, viewText]);
        if (newStr === `connectors.${viewOption.connector}`) {
          console.warn('[SEARCH] Error creating seo value, wrong option', {
            viewOption,
            query
          });
        } else {
          str = newStr;
          str = str.slice(0, 1).toUpperCase() + str.slice(1);
        }
      }
    }

    if (query.county === 'constempty') {
      // delete query.county;
      query.county = t('search.allCounties').toLowerCase();
    }

    if (query.city === 'constempty') {
      // delete query.city;
      query.city = t('search.allCities').toLowerCase();
    }

    if (query.zipcode === 'constempty') {
      // delete query.zipcode;
      query.zipcode = t('search.allZipcodes').toLowerCase();
    }

    if (query.neighborhood === 'constempty') {
      // delete query.neighborhood;
      query.neighborhood = t('search.allNeighborhoods').toLowerCase();
    }

    const inValues = [];

    if (query.county) {
      inValues.push(query.county);
    }

    if (query.city) {
      inValues.push(query.city);
    }

    if (query.zipcode) {
      inValues.push(query.zipcode);
    }

    if (query.neighborhood) {
      inValues.push(capitalize(query.neighborhood));
    }

    if (query.location) {
      inValues.push(capitalize(query.location));
    }

    if (inValues.length > 0) {
      str = t('connectors.in', [str, inValues.join(', ')]);
    }

    // if (type === 'title') {
    //   const list = ['priceTo', 'priceFrom', 'bedrooms', 'bathsFull'];

    //   for (const key of list) {
    //     if (query[key]) {
    //       list.filter(k => k !== key).forEach(k => delete query[k]);
    //       break;
    //     }
    //   }
    // }

    if (query.priceFrom) {
      const price = t('seo.numberArticle', [n(query.priceFrom, 'currency')]);
      str = t('connectors.from', [str, price]);
    }

    if (query.priceTo) {
      const price = t('seo.numberArticle', [n(query.priceTo, 'currency')]);
      str = t('connectors.to', [str, price]);
    }

    const features: [string, 'with' | 'from' | 'to' | 'without'][] = [];

    if (query.bedroomsFrom && !query.bedroomsTo) {
      features.push([
        t('search.nOrMoreBedrooms', [query.bedroomsFrom]),
        'with'
      ]);
    }

    if (query.bedroomsTo && !query.bedroomsFrom) {
      features.push([t('search.nOrLessBedrooms', [query.bedroomsTo]), 'with']);
    }

    if (query.bedroomsFrom && query.bedroomsTo) {
      features.push([
        t('search.nBedrooms', [
          [query.bedroomsFrom, query.bedroomsTo].join('-')
        ]),
        'with'
      ]);
    }

    if (query.bathroomsFrom && !query.bathroomsTo) {
      features.push([
        t('search.nOrMoreBathrooms', [query.bathroomsFrom]),
        'with'
      ]);
    }

    if (query.bathroomsTo && !query.bathroomsFrom) {
      features.push([
        t('search.nOrLessBathrooms', [query.bathroomsTo]),
        'with'
      ]);
    }

    if (query.bathroomsFrom && query.bathroomsTo) {
      features.push([
        t('search.nBathrooms', [
          [query.bathroomsFrom, query.bathroomsTo].join('-')
        ]),
        'with'
      ]);
    }

    if (query.areaFrom || query.areaTo) {
      let areaStr = '';

      if (query.areaFrom) {
        areaStr = t('connectors.from', [
          areaStr,
          t('seo.numberArticle', [t('search.nArea', [query.areaFrom])])
        ]);
      }

      if (query.areaTo) {
        areaStr = t('connectors.to', [
          areaStr,
          t('seo.numberArticle', [t('search.nArea', [query.areaTo])])
        ]);
      }

      areaStr = t('connectors.ranging', [t('search.areas'), areaStr]);

      features.push([areaStr, 'with']);
    }

    if (query.yearFrom || query.yearTo) {
      const connectorType = !query.yearFrom ? 'to' : 'from';
      let yearStr = '';

      if (query.yearFrom) {
        yearStr = t('seo.numberArticleSingle', [query.yearFrom]);
      }

      if (query.yearTo) {
        yearStr = query.yearFrom
          ? t('connectors.to', [
              yearStr,
              t('seo.numberArticleSingle', [query.yearTo])
            ])
          : t('seo.numberArticleSingle', [query.yearTo]);
      }

      features.push([yearStr, connectorType]);
    }

    if (query.shortTermLease) {
      features.push([t('search.shortTermLease').toLowerCase(), 'with']);
    }

    if (query.excludePendingAndUnderContract) {
      features.push([
        t('search.pendingAndUnderContractProperties').toLowerCase(),
        'without'
      ]);
    }

    const withFeatures = features.filter(f => f[1] === 'with').map(f => f[0]);
    const fromFeatures = features.filter(f => f[1] === 'from').map(f => f[0]);
    const toFeatures = features.filter(f => f[1] === 'to').map(f => f[0]);
    const withoutFeatures = features
      .filter(f => f[1] === 'without')
      .map(f => f[0]);

    if (withFeatures.length > 0) {
      str = t('connectors.with', [str, joinAnd(withFeatures, t)]);
    }

    if (fromFeatures.length > 0) {
      str = t('connectors.from', [str, joinAnd(fromFeatures, t)]);
    }

    if (toFeatures.length > 0) {
      str = t('connectors.to', [str, joinAnd(toFeatures, t)]);
    }

    if (withoutFeatures.length > 0) {
      str = t('connectors.without', [str, joinOr(withoutFeatures, t)]);
    }

    str += ` ${t('seo.atFlorida')}`;

    if (['hopa-eligible', 'no-hopa'].includes(query.hopa || '')) {
      str += ` (${t(`search.hopaOptions.${query.hopa}`)})`;
    }

    if (type === 'description') {
      str += t('seo.complementaryDescription');
    } else {
      str += ' - ' + t('seo.defaultTitle');
    }

    // console.log(
    //   `Search seo of type ${type}: ${str} (using the following parameters)`,
    //   queryArg
    // );

    return str.replace(/\s/g, ' ').replace(/  +/g, ' ');
  }

  const subTypeContextMap = propertySubTypeOptions.reduce((acc, cur) => {
    acc[cur.value] = cur.context;
    return acc;
  }, {} as Record<string, 'male' | 'female'>);

  function getPropertySeo(
    inputProperty: PropertyDocumentResponse,
    type: 'title' | 'description' | 'address' = 'title'
  ) {
    if (type === 'title') {
      return getPropertySeoTitle(inputProperty);
    }

    let str = '';

    // propertyType: , TODO: types
    const paramsByType = {
      title: [
        'subType',
        'type',
        'buyType',
        'city',
        'county',
        'full',
        // 'postalCode',
        'id'
      ],
      description: [
        'photos',
        'listPrice',
        'subType',
        'type',
        'buyType',
        'city',
        'county',
        'full',
        // 'postalCode',
        'state',
        'yearBuilt',
        'id'
      ],
      address: ['full', 'city', 'postalCode']
    };

    const paramsInType = paramsByType[type];

    const property = objectFilter(inputProperty, (_v, key) =>
      ['subType', 'type', 'buyType', ...paramsInType].includes(key)
    ) as Partial<PropertyDocumentResponse>;

    const propertyType =
      subTypeToTypeMap.value[property.subType] || t('seo.defaultSingle');

    const propertySubType =
      subTypeMap.value[property.subType] || t('seo.defaultSingle');

    let typeStr = '';

    if (paramsInType.includes('type') && paramsInType.includes('subType')) {
      typeStr = t('connectors.type', [propertyType, propertySubType]);
    } else if (paramsInType.includes('type')) {
      typeStr = propertyType;
    } else if (paramsInType.includes('subType')) {
      typeStr = propertySubType;
    }

    if (property.listPrice) {
      const priceStr = n(property.listPrice, 'currency', language.value);
      typeStr = t('connectors.ofProperty', [typeStr, priceStr]);
    }

    // Add "Discover" at the beginning for descriptions
    if (type === 'description') {
      str = t('seo.discover', [typeStr]);
    } else {
      str = typeStr;
    }

    if (str && paramsInType.includes('buyType')) {
      if (property.type === 'RNT') {
        str = t('seo.singleRent', [str]);
      } else if (property.type !== undefined) {
        str = t('seo.singleSale', [str]);
      }
    }

    const address: string[] = [];

    paramsInType.forEach(key => {
      if (['full', 'city', 'county', 'postalCode'].includes(key)) {
        const propertyKey = key as keyof PropertyDocumentResponse;
        if (property[propertyKey]) {
          if (key === 'postalCode') {
            address.push('FL ' + property.postalCode);
          } else {
            address.push(property[propertyKey].toString());
          }
        }
      }
    });

    const addressStr = address.join(type === 'address' ? ' ' : ', ');

    if (addressStr) {
      if (str) {
        str = t('connectors.in', [str, addressStr]);
      } else {
        str = addressStr;
      }
    }

    const features: string[] = [];

    if (property.bedrooms) {
      features.push(t('search.nBedrooms', [property.bedrooms]));
    }

    if (property.bathsFull) {
      features.push(t('search.nBathrooms', [property.bathsFull]));
    }

    if (features.length > 0) {
      str = t('connectors.with', [str, joinAnd(features, t)]);
    }

    // if (property.listPrice) {
    //   str += `. ${t('search.price')}: ` + n(property.listPrice, 'currency');
    // }

    const context = subTypeContextMap[property.subType];

    if (property.yearBuilt) {
      str = t(
        context === 'male' ? 'connectors.builtIn' : 'connectors.builtIn_female',
        [str, property.yearBuilt]
      );
    }

    // if (property.photos?.length) {
    //   str = t(context === 'male' ? 'seo.hasPhotos' : 'seo.hasPhotos_female', [
    //     str,
    //     property.photos.length
    //   ]);
    // }

    if (property.id) {
      str += '. ' + t('seo.mlsId', [property.id]);
    }

    if (type === 'description') {
      str += '.';
      // str += ' ' + (t('seo.complementaryDescription'));
    }

    // else if (type === 'title') {
    //   // str += '.';
    //   str += ' - ' + t('seo.defaultTitle');
    // }

    return str.replace(/\s/g, ' ').replace(/  +/g, ' ');
  }

  function getProjectSeo(
    project: ProjectDocumentResponse,
    type: 'title' | 'description' = 'title'
  ) {
    if (type === 'title') {
      return getProjectSeoTitle(project);
    }

    let str = '';

    const propertyType = t('seo.project');
    // propertyTypesPluralMap[
    //   project.subType as keyof typeof propertyTypesPluralMap
    // ] || t('seo.defaultMultiple');

    if (project.projectStatus === 'pre-construction') {
      str += t('seo.preConstruction', [propertyType]);
    } else if (project.projectStatus === 'under-construction') {
      str += t('seo.underConstruction', [propertyType]);
    } else if (project.projectStatus === 'built') {
      str += t('seo.built', [propertyType]);
    } else if (project.projectStatus === 'on-the-market') {
      str += t('seo.onTheMarket', [propertyType]);
    }

    const address: string[] = [];

    if (project.city) {
      address.push(project.city);
    }
    if (project.county) {
      address.push(project.county);
    }

    const addressStr = address.join(', ');

    if (addressStr) {
      if (str) {
        str = t('connectors.in', [str, addressStr]);
        str += '.';
      } else {
        str = addressStr;
      }
    }

    if (project.projectName) {
      str += ` ${project.projectName}`;
    }

    if (type === 'description') {
      if (project.photos?.length) {
        str = t('seo.hasPhotos', [str, project.photos.length]);
      }

      if (project.minPrice) {
        str +=
          `. ${t('search.priceFrom')}: ` +
          n(project.minPrice, 'currency', language.value);
      }

      if (project.yearCompletion) {
        if (['built', 'on-the-market'].includes(project.projectStatus)) {
          str += `. ${t('seo.yearBuilt', [project.yearCompletion])}`;
        } else {
          str += `. ${t('seo.yearCompletion', [project.yearCompletion])}`;
        }
      }

      if (project.totalUnits) {
        str += `. ${t('seo.nResidences', [project.totalUnits])}`;
      }

      str += t('seo.complementaryDescription');
    } else {
      str += ' - ' + t('seo.defaultTitle');
    }

    // console.log(
    //   `Project seo of type ${type}: ${str} (using the following parameters)`,
    //   project
    // );

    return str.replace(/\s/g, ' ').replace(/  +/g, ' ');
  }

  function getProjectSchemaName(
    project: ProjectDocumentResponse,
    index?: number
  ) {
    const { address, subType, projectName } = project;

    // Get the translated subType using the existing subTypeMap
    let str = subTypeToTypeMap.value[subType] || t('search.property');

    if (index) {
      str += ` ${index}`;
    }

    // Add project name if available
    if (projectName) {
      str = t('connectors.in', [str, projectName]);
    }

    // Add address
    if (address) {
      str = t('connectors.in', [str, address]);
    }

    return str;
  }

  function getProjectSchemaDescription(project: ProjectDocumentResponse) {
    const { address, subType, maxBeds, projectName } = project;

    // Get the translated property type
    const typeLabel = subTypeToTypeMap.value[subType] || t('search.property');

    const subTypeLabel = subTypeMap.value[subType] || t('search.property');

    let str =
      typeLabel !== subTypeLabel
        ? t('connectors.type', [typeLabel, subTypeLabel])
        : typeLabel;

    // Add maxBeds if available using "up to" connector
    if (maxBeds) {
      str = t('connectors.with', [str, t('search.nOrLessBedrooms', [maxBeds])]);
    }

    // Add project name if available
    if (projectName) {
      str = t('connectors.in', [str, projectName]);
    }

    // Add location
    if (address) {
      str = t('connectors.in', [str, address]);
    }

    // Add period at the end
    return `${str}.`;
  }

  return {
    // Search SEO
    getSearchSummary,
    getSearchSeoTitle,
    getSearchSeoDescription,

    // Property SEO
    getPropertySeoTitle,
    getPropertySeoDescription,
    getPropertySeoAddress,

    // Proerty Schema
    getPropertySchemaName,
    getPropertySchemaDescription,

    // Project SEO
    getProjectSeoTitle,
    getProjectSeoDescription,

    // Project Schema
    getProjectSchemaName,
    getProjectSchemaDescription,

    // Base functions (in case they're needed directly)
    getSearchSeo,
    getPropertySeo,
    getProjectSeo
  };
}
